@import "./assets/styles/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  outline: none;
}

button:hover {
  filter: brightness(1.1);
  transition: all 0.5s ease-in-out;
}

.donutchart-innertext {
  display: none;
}

.image-item {
  padding: 20px 0 20px 20px;
}

.hide-scroll::-webkit-scrollbar {
  width: 0.5em;
}

.hide-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hide-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.css-1bekpqd-MuiTableCell-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

td {
  text-align: center !important;
}