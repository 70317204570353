@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* @font-face {
  font-family: "Gotham";
  src: local("GothamMedium"),
    url("../fonts/gotham/Gotham-Medium.woff") format("woff");
  font-weight: normal;
} */

@font-face {
  font-family: "Gotham";
  src: local("GothamBold"),
    url("../fonts/gotham/Gotham-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url("../fonts/gotham/Gotham-Medium.woff") format("woff");
}

@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url("../fonts/gotham/Gotham-Book.woff") format("woff");
  font-weight: lighter;
}
